<template>
  <div>
    <v-text-field
      class="c-input-small"
      v-model="filters.name"
      @keyup.enter="getList"
      :label="$t('labels.goods_name')"
      :placeholder="$t('labels.goods_name')"
      dense
      outlined
      clearable
      hide-details
      single-line
    ></v-text-field>

    <v-list
      dense
      style="height: calc(100vh - 170px)"
      class="overflow-x-hidden overflow-y-auto"
    >
      <div v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title class="white-space-normal">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular white-space-normal"
            >
              <span class="">{{ item.description }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="black--text font-weight-medium">
                {{ item.size }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.barcode") }}:
              <span class="font-weight-medium error--text">
                {{ item.customer_goods_barcode }}
              </span>
              <span> - </span>
              {{ $t("labels.sku") }}:
              <span class="font-weight-medium primary--text">
                {{ item.sku }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.available") }}:
              <span class="font-weight-medium success--text">
                {{ item.available }}
              </span>
            </v-list-item-subtitle>
            <!-- <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.note") }}: {{ item.comment }}
            </v-list-item-subtitle> -->
          </v-list-item-content>

          <v-list-item-avatar rounded="0">
            <ImageViewer
              :url="item.url_images || require('@/assets/common/no-image.jpg')"
            />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <div>
            <div>
              <v-btn
                color="primary"
                x-small
                class="mr-1"
                @click="showCheckDialog(item)"
              >
                {{ $t("labels.check") }}
              </v-btn>
            </div>
          </div>
        </v-list-item>

        <v-row dense class="my-1" v-if="selected.id == item.id">
          <v-col cols="7">
            <v-text-field
              :label="$t('labels.quantity')"
              autofocus
              class="c-input-small"
              type="number"
              v-model.number="quantity"
              dense
              outlined
              hide-details
              min="1"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-btn
              color="success"
              style="height: 40px"
              block
              @click="receiptItem"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider v-if="index < items.length - 1"></v-divider>
      </div>
    </v-list>
    <div class="mt-3">
      <v-pagination
        class="mini-pagination"
        v-model="page"
        :length="totalPage"
        :total-visible="2"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  downloadExcelFile,
  downloadPdfFile,
  formatDateTime,
  formatNumber,
} from "@/libs/helpers";
export default {
  name: "ReceiptPresent",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  data: () => ({
    isLoading: false,
    selected: {},
    quantity: null,
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
    dialogCheck: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    downloadPdfFile,
    downloadExcelFile,
    formatNumber,
    showCheckDialog(item) {
      this.quantity = null;
      this.selected = { ...item };
      this.dialogCheck = true;
    },
    hideCheckDialog() {
      this.quantity = null;
      this.selected = {};
      this.dialogCheck = false;
      this.getList();
    },
    async receiptItem() {
      if (
        !this.quantity ||
        this.quantity < 1 ||
        !this.selected ||
        !this.selected.id
      ) {
        return false;
      }

      if (!confirm(this.$t("messages.are_you_sure"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/dc/v1/receipt-present", {
          ...this.selected,
          quantity: this.quantity,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.hideCheckDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    getList() {
      httpClient
        .post("/dc/v1/goods-presents", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    },
  },
};
</script>
